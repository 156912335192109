import { gsap } from './app/gsap';
import { ScrollTrigger } from './app/ScrollTrigger.js';
import { ScrollSmoother } from './app/ScrollSmoother.js';
import { ScrollToPlugin } from './app/ScrollToPlugin.js';
import { MorphSVGPlugin } from './app/MorphSVGPlugin.js';

let semiMaskOffset = null;

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
ScrollSmoother.create({
	smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
	effects: true, // looks for data-speed and data-lag attributes on elements
	smoothTouch: 0.3, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
	onUpdate: () => {
		fixedElements();
	},
});

if (document.querySelector('.sliding-masthead')) {
	triggerOpening();
}

if (document.body.classList.contains('single-farlo_work')) {
	if (!document.body.classList.contains('postid-1090')) {
		projectPageAnimations();
	}
}

const product_masks = document.querySelectorAll('.image-mask');

let resizeTimeout;
const animationData = new Map();
if (product_masks) {
	buildProductMasks();
}

function calculateMaskHeight() {
	const minWidth = 360;
	const maxWidth = 1500;
	const minVariable = 600;
	const maxVariable = 800;

	// Get the current window width
	const currentWidth = window.innerWidth;

	// Ensure the width stays within boundaries
	const clampedWidth = Math.max(minWidth, Math.min(maxWidth, currentWidth));

	// Scale the variable based on the clamped width
	const variable =
		((clampedWidth - minWidth) / (maxWidth - minWidth)) *
			(maxVariable - minVariable) +
		minVariable;

	return parseInt(variable, 10);
}

function buildProductMasks() {
	product_masks.forEach(async (_productMask) => {
		stopFlickering(_productMask);

		// _productMask.height = calculateMaskHeight();
		// _productMask.width = window.innerWidth;
		const maskCanvas = _productMask;
		const maskContext = maskCanvas.getContext('2d', {
			willReadFrequently: true,
		});
		const maskCanvasAspectRatio = maskCanvas.width / maskCanvas.height;

		maskContext.clearRect(0, 0, maskCanvas.width, maskCanvas.height);

		const matte = document.createElement('img');
		matte.src = _productMask.dataset.img;

		const offscreenCanvas = new OffscreenCanvas(
			maskCanvas.width,
			maskCanvas.height
		);
		const offscreenContext = offscreenCanvas.getContext('2d');

		const mask = document.createElement('img');
		mask.src = URL.createObjectURL(
			new Blob(
				[
					new XMLSerializer().serializeToString(
						document.querySelector(
							'#svg-mask-' + _productMask.dataset.id + ' > svg'
						)
					),
				],
				{ type: 'image/svg+xml' }
			)
		);

		await Promise.all([
			new Promise((resolve) =>
				mask.addEventListener('load', resolve, { once: true })
			),
			matte.complete ||
				new Promise((resolve) =>
					matte.addEventListener('load', resolve, { once: true })
				),
		]);

		const matteAspectRatio = matte.naturalWidth / matte.naturalHeight,
			maskAspectRatio = mask.naturalWidth / mask.naturalHeight;

		let width, height, top, left;

		[width, height] =
			maskCanvasAspectRatio <= matteAspectRatio
				? [maskCanvas.height * matteAspectRatio, maskCanvas.height]
				: [maskCanvas.width, maskCanvas.width / matteAspectRatio];

		[top, left] = [
			(maskCanvas.height - height) / 2,
			(maskCanvas.width - width) / 2,
		];

		maskContext.drawImage(
			matte,
			0,
			0,
			matte.naturalWidth,
			matte.naturalHeight,
			left,
			top,
			width,
			height
		);

		[width, height] =
			maskCanvasAspectRatio <= maskAspectRatio
				? [maskCanvas.width, maskCanvas.width / maskAspectRatio]
				: [maskCanvas.height * maskAspectRatio, maskCanvas.height];

		[top, left] = [
			(maskCanvas.height - height) / 2,
			(maskCanvas.width - width) / 2,
		];

		maskContext.globalCompositeOperation = 'destination-in';

		offscreenContext.drawImage(
			mask,
			0,
			0,
			_productMask.width,
			_productMask.height
		);

		applyGaussianBlur(offscreenCanvas, offscreenContext, 5);

		maskContext.drawImage(
			offscreenCanvas,
			0,
			0,
			_productMask.width,
			_productMask.height
		);

		if (_productMask.dataset.colour) {
			const imageData = maskContext.getImageData(
				0,
				0,
				_productMask.width,
				_productMask.height
			);
			const filteredImageData = rgbFilter(
				imageData,
				_productMask.dataset.colour
			);
			maskContext.putImageData(filteredImageData, 0, 0);
		}
		if (!animationData.get(maskContext.canvas)) {
			animationData.set(_productMask, -1);
		}
		if (_productMask.dataset.effect == 'glitch') {
			const animationId = startFlickering(
				maskContext,
				_productMask.width,
				_productMask.height
			);
			animationData.set(_productMask, animationId);
		}
	});
}

function clearAnimations() {
	animationData.forEach((animationId) => {
		cancelAnimationFrame(animationId);
	});
	animationData.clear();
}

function stopFlickering(_productMask) {
	const animationId = animationData.get(_productMask);
	if (animationId) {
		cancelAnimationFrame(animationId);
		animationData.delete(_productMask);
	}
}

function getRandomOffset() {
	return parseInt(Math.random() * 200) - 100;
}

function rgbSplitEffect(context, imageData, offsets) {
	const updatedImageData = rgbSplit(imageData, offsets);
	context.putImageData(updatedImageData, 0, 0);
}

function resetEffect(context, imageData) {
	const updatedImageData = rgbSplit(imageData, {
		rOffset: 0,
		gOffset: 0,
		bOffset: 0,
	});
	context.putImageData(updatedImageData, 0, 0);
}

function flickerEffect(context, width, height) {
	const imageData = context.getImageData(0, 0, width, height);

	const offsets = {
		rOffset: getRandomOffset(),
		gOffset: getRandomOffset(),
		bOffset: getRandomOffset(),
	};

	rgbSplitEffect(context, imageData, offsets);

	setTimeout(() => {
		resetEffect(context, imageData);
	}, 50);
}

function startFlickering(maskContext, width, height) {
	function flickerLoop(timestamp) {
		if (!lastTimestamp) {
			lastTimestamp = timestamp;
		}

		const elapsed = timestamp - lastTimestamp;

		if (elapsed > 3000 + Math.random() * 3000) {
			flickerEffect(maskContext, width, height);

			// Occasionally flicker twice in rapid succession
			if (Math.random() < 0.2) {
				setTimeout(
					() => flickerEffect(maskContext, width, height),
					100
				);
			}

			lastTimestamp = timestamp;
		}

		const animationId = animationData.get(maskContext.canvas);

		if (animationId) {
			if (animationId === -1) {
				const thisAnimation = requestAnimationFrame(flickerLoop);
				animationData.set(maskContext.canvas, thisAnimation);
			} else {
				requestAnimationFrame(flickerLoop);
			}
		} else {
			// If animationId is not present, the flickering for this mask has been stopped
			lastTimestamp = null;
		}
	}

	let lastTimestamp = null;
	flickerLoop();

	return animationData.get(maskContext.canvas);
}

function hexToRgb(hex) {
	// Remove the hash (#) if present
	hex = hex.replace(/^#/, '');

	// Parse the hex value into separate RGB components
	const bigint = parseInt(hex, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;

	// Return an object with RGB values
	return { r, g, b };
}

function rgbFilter(imageData, hex) {
	// Convert the hex color to RGB
	const rgbColor = hexToRgb(hex);

	const originalArray = imageData.data;
	const newArray = new Uint8ClampedArray(originalArray);

	// Apply the hex color blending filter
	for (let i = 0; i < originalArray.length; i += 4) {
		// Skip transparent pixels
		if (newArray[i + 3] !== 0) {
			// Blend the colors
			newArray[i] = overlayBlend(originalArray[i], rgbColor.r); // 🔴
			newArray[i + 1] = overlayBlend(originalArray[i + 1], rgbColor.g); // 🟢
			newArray[i + 2] = overlayBlend(originalArray[i + 2], rgbColor.b); // 🔵
		}
	}
	// return a new ImageData object
	return new ImageData(newArray, imageData.width, imageData.height);
}

function overlayBlend(base, blend) {
	// Overlay blend mode calculation
	return base < 128
		? (2 * base * blend) / 255
		: 255 - (2 * (255 - base) * (255 - blend)) / 255;
}

function rgbSplit(imageData, options) {
	// destructure the offset values from options, default to 0
	const { rOffset = 0, gOffset = 0, bOffset = 0 } = options;
	// clone the pixel array from original imageData
	const originalArray = imageData.data;
	const newArray = new Uint8ClampedArray(originalArray);
	// loop through every pixel and assign values to the offseted position
	for (let i = 0; i < originalArray.length; i += 4) {
		newArray[i + 0 + rOffset * 4] = originalArray[i + 0]; // 🔴
		newArray[i + 1 + gOffset * 4] = originalArray[i + 1]; // 🟢
		newArray[i + 2 + bOffset * 4] = originalArray[i + 2]; // 🔵
	}
	// return a new ImageData object
	return new ImageData(newArray, imageData.width, imageData.height);
}

function applyGaussianBlur(canvas, context, radius) {
	context.filter = 'blur(' + radius + 'px)';
	context.drawImage(canvas, 0, 0);
	context.filter = 'none';
}

function projectGalleries(_elem, columns = 2) {
	// Target the gallery images
	const galleryImages = document.querySelectorAll(_elem + ' figure');
	const images = [];
	galleryImages.forEach((image, index) => {
		const halfWidth = window.innerWidth / columns / 2;
		let moveDirection;

		// Determine the row based on the index and the number of columns
		const row = Math.floor(index / columns);
		// Alternate the direction for the entire row
		// Rows with an even number (including the first row) move in one direction
		// Rows with an odd number move in the opposite direction
		if (row % 2 === 0) {
			moveDirection = halfWidth; // Move right for even rows
		} else {
			moveDirection = -halfWidth; // Move left for odd rows
		}

		let nextImage = galleryImages[0];
		if (galleryImages[index + 1]) {
			nextImage = galleryImages[index + 1];
		}
		images.push(nextImage.querySelector('img').src);

		const tl = gsap.timeline();
		tl.from(image, {
			x: moveDirection,
			duration: 5,
			ease: 'none',
		}).to(image, {
			x: -moveDirection,
			duration: 5,
			ease: 'none',
		});

		ScrollTrigger.create({
			trigger: image,
			animation: tl,
			start: 'top bottom',
			end: 'bottom top',
			scrub: 1,
		});
	});

	let cssStr = '';
	for (let i = 0; i < images.length; i++) {
		cssStr += `${_elem} figure:nth-child(${
			i + 1
		}):before { background-image:url(${images[i]}); }`;
	}
	cssStr += `${_elem} figure:last-child:after { background-image:url(${images.pop()}); }`;
	const css = document.createElement('style');
	css.innerHTML = cssStr;
	document.body.appendChild(css);

	// Adjust gallery grid based on columns
	document.querySelectorAll(_elem).forEach((gallery) => {
		gallery.style.setProperty('--columns', columns);
	});
}

if (document.querySelector('.site-project__gallery')) {
	projectGalleries('.site-project__gallery');
}
if (document.querySelector('.alternating-gallery')) {
	projectGalleries('.alternating-gallery > .wp-block-gallery');
}
if (document.querySelector('.site-partner-logos--full-width-gallery')) {
	projectGalleries('.site-partner-logos--full-width-gallery-inner', 3);
}

function projectsNavigation() {
	const nav = document.querySelector('.site-projects__navigation');
	const navHeight = nav.offsetHeight; // Capture the nav height for potential adjustments

	// Select all navigation buttons
	const navButtons = nav.querySelectorAll(
		'.site-projects__navigation-list-item-link'
	);

	// Iterate over each button to add a click event listener
	navButtons.forEach((button) => {
		button.addEventListener('click', function () {
			// Retrieve the section ID from the button's data attribute
			const sectionId = this.getAttribute('data-section-id');
			// Select the target section using the section ID
			const targetSection = document.getElementById(sectionId);

			if (targetSection) {
				gsap.to(window, {
					scrollTo: {
						y: targetSection,
						offsetY: navHeight,
					},
					duration: 1,
				});
			}
		});
	});
}

function scrollToSection(hash) {
	if (hash) {
		const section = document.getElementById(hash.split('#')[1]);

		if (section) {
			gsap.to(window, {
				duration: 2,
				scrollTo: {
					y: section,
					offsetY:
						document.querySelector('.site-header').offsetHeight,
				},
			});
		}
	}
}

if (window.location && typeof window.location.hash !== 'undefined') {
	scrollToSection(window.location.hash);
}

document.querySelectorAll('a').forEach((_a) => {
	if (_a.href) {
		const url = new URL(_a.href);
		if (url.pathname === location.pathname && url.hash) {
			const target = document.querySelector(url.hash);
			if (target) {
				_a.addEventListener('click', () => {
					gsap.to(window, {
						scrollTo: {
							y: target,
						},
						duration: 1,
					});
				});
			}
		}
	}
});

if (document.querySelector('.site-projects__navigation')) {
	projectsNavigation();
}

function triggerOpening() {
	const panels = gsap.utils.toArray('.sliding-masthead__section-content');
	let horizontalScrollWidth = 0;
	panels.forEach((panel) => {
		horizontalScrollWidth += panel.offsetWidth;
	});
	const imagePanels = gsap.utils.toArray('.sliding-masthead__section');

	const scrollTimeline = gsap
		.timeline({
			scrollTrigger: {
				trigger: '.sliding-masthead',
				start: 'top top',
				end: () => `+=${horizontalScrollWidth} top`,
				scrub: true,
				pin: true,
			},
		})
		.add('sliders')
		.to(
			'.sliding-masthead__cover',
			{
				duration: 8,
				x: () => -(horizontalScrollWidth - panels[0].offsetWidth * 2),
				ease: 'none',
			},
			'sliders'
		)
		.to(
			panels,
			{
				duration: 8,
				x: () => -(horizontalScrollWidth - panels[0].offsetWidth),
				ease: 'none',
			},
			'sliders'
		)
		.to(
			imagePanels,
			{
				duration: 2,
				opacity: () => 0,
				ease: 'none',
				stagger: {
					each: 4,
					from: 'start',
				},
			},
			'sliders'
		);
}

if (document.querySelector('.current-show')) {
	const panels = gsap.utils.toArray('.current-show');
	const tops = panels.map((panel) =>
		ScrollTrigger.create({ trigger: panel, start: 'top top' })
	);

	panels.forEach((panel, i) => {
		const tween = gsap.timeline();
		tween.add('start');
		if (i > 0) {
			tween.from(
				panel.querySelector('.image-mask'),
				{
					opacity: 0,
					duration: 1,
					force3D: true,
				},
				'start'
			);
			tween.from(
				panel.querySelector('.current-show__info'),
				{
					opacity: 0,
					y: 200,
					duration: 1,
					force3D: true,
				},
				'start'
			);
		}
		tween.to(
			panel.querySelector('.image-mask'),
			{
				opacity: 0.5,
				duration: 1,
				force3D: true,
			},
			'start'
		);
		tween.add('end');
		if (i < panels.length - 1) {
			tween.to(
				panel.querySelector('.image-mask'),
				{
					opacity: 0,
					duration: 1,
					force3D: true,
				},
				'end'
			);

			tween.to(
				panel.querySelector('.current-show__info'),
				{
					opacity: 0,
					y: -200,
					duration: 1,
					force3D: true,
				},
				'end'
			);
		}
		ScrollTrigger.create({
			trigger: panel,
			start: () =>
				panel.offsetHeight < window.innerHeight
					? 'top top'
					: 'bottom bottom',
			animation: tween,
			scrub: true,
			pin: true,
			pinSpacing: false,
		});
	});
}

function fixedElements() {
	if (document.querySelector('.site-projects__navigation')) {
		const container = document.querySelector('.site-farlo-site-projects');
	}
	if (document.querySelector('#projects-timeline')) {
		// const container = document.querySelector("#projects");
		// document.querySelector("#projects-timeline").style.top = (container.getBoundingClientRect().top * -1) + (window.innerHeight * 0.5) + "px"
	}

	// Homepage Inline Current Shows
	if (document.querySelector('.current-show')) {
		const container = document.querySelector('.site-farlo-site-projects');
		semiMaskOffset = container.getBoundingClientRect().top * -1;
		if (
			container.getBoundingClientRect().top < 0 &&
			semiMaskOffset <= container.offsetHeight
		) {
			document.documentElement.style.setProperty(
				'--current-shows-fixed-mask-offset',
				semiMaskOffset + window.innerHeight * 0.5 + 'px'
			);
		}
	}
}

function projectPageAnimations() {
	const panels = gsap.utils.toArray('.primary-section');
	// const scrollTimeline = gsap
	// .timeline({
	// 	scrollTrigger: {
	// 		trigger: '.primary-section-container',
	// 		start: 'top top',
	// 		end: 'bottom bottom',
	// 		// scrub: true,
	// 		// pin: true,
	// 	}
	// })
	panels.forEach((panel, i) => {
		if (
			panel.id !== 'book' &&
			panel.id !== 'faqs' &&
			panel.id !== 'booking-terms'
		) {
			const tween = gsap.timeline();
			const ps = gsap.utils.toArray('*', panel);
			ps.forEach((_p) => {
				_p.classList.add('fade-in-up');
			});
			ScrollTrigger.batch(ps, {
				batchMax: 1, // maximum batch size (targets)
				onEnter: (batch) =>
					gsap.to(batch, {
						opacity: 1,
						y: 0,
						stagger: 1,
						overwrite: true,
					}),
			});
		}
	});
	const h1 = document.querySelector('h1');
	if (h1) {
		const arr = h1.textContent.split('');
		const spans = [];
		arr.forEach((_c) => {
			if (_c == ' ') {
				spans.push(
					"</span><span class='page-title-letter page-title-space'>&nbsp;</span><span class='page-title-word'>"
				);
			} else {
				spans.push("<span class='page-title-letter'>" + _c + '</span>');
			}
		});
		h1.innerHTML =
			"<span class='page-title-letters'><span class='page-title-word'>" +
			spans.join('') +
			'</span></span>';
	}
	const letters = gsap.utils.toArray('.page-title-letter');
	const scrollTimeline = gsap
		.timeline({
			scrollTrigger: {
				trigger: '#project-header',
				start: 'top bottom',
			},
		})
		.to(letters, {
			duration: 0.3,
			transform: 'scale(1, 1)',
			ease: 'none',
			stagger: {
				each: 0.05,
				from: 'start',
			},
		});
}

function projectCardIntro() {
	const cards = gsap.utils.toArray('.project-item.archive-project');

	cards.forEach((_card) => {
		_card.classList.add('fade-in-up');
	});
	ScrollTrigger.batch(cards, {
		batchMax: 2,
		onEnter: (batch) =>
			gsap.to(batch, {
				opacity: 1,
				y: 0,
				ease: 'none',
				duration: 0.3,
				stagger: {
					each: 0.3,
					from: 'start',
				},
			}),
	});
}
if (document.querySelector('#projects-container, .layout-card')) {
	projectCardIntro();
}

function fading_partner_gallery() {
	const cards = gsap.utils.toArray('.site-partner-logo__gallery-image');
	ScrollTrigger.batch(cards, {
		batchMax: 2,
		onEnter: (batch) =>
			gsap.to(batch, {
				opacity: 1,
				y: 0,
				ease: 'none',
				duration: 0.3,
				stagger: {
					each: 0.3,
					from: 'start',
				},
			}),
	});
}

function fadingGallery() {
	document.querySelectorAll('.fading-gallery').forEach((_gallery) => {
		const images = gsap.utils.toArray('.wp-block-image', _gallery);
		images.forEach((_image) => {
			_image.classList.add('fade-in-up');
		});
		ScrollTrigger.batch(images, {
			batchMax: 2,
			onEnter: (batch) =>
				gsap.to(batch, {
					opacity: 1,
					y: 0,
					ease: 'none',
					duration: 0.3,
					stagger: {
						each: 0.3,
						from: 'start',
					},
				}),
		});
	});
}

if (document.querySelector('.fading-gallery')) {
	fadingGallery();
}

if (document.querySelector('.site-partner-logos--fading-gallery')) {
	fading_partner_gallery();
}

function vr_feature() {
	var tl = gsap.timeline(),
		moon1 = document.getElementById('moon-start');
	tl.add('start')
		.to(
			moon1,
			{
				morphSVG: '#moon-end',
				duration: 10,
				// fill: "#3e3e3e",
			},
			'start'
		)
		.to(
			'.vr-feature__moon-image img',
			{
				filter: 'drop-shadow(0px 0px 40vw white) drop-shadow(0px 0px 80px white)',
				duration: 10,
			},
			'start'
		)
		.to(
			'.vr-feature__castle-element',
			{
				opacity: 1,
				duration: 5,
			},
			'start+=5'
		)
		.to(
			moon1,
			{
				fill: '#2e2e2e',
				duration: 8,
			},
			'start+=2'
		)
		.add('end')
		.to(
			'.vr-feature__lighting',
			{
				transform: 'scaleX(1)',
				duration: 6,
				opacity: 1,
				ease: 'none',
			},
			'end-=7'
		)
		.to(
			moon1,
			{
				opacity: 0,
				duration: 3,
			},
			'end-=3'
		)
		.to(
			'.vr-feature__ftl',
			{
				opacity: 1,
				filter: 'blur(0px)',
				duration: 3,
			},
			'end-=7'
		);
}

if (document.querySelector('.vr-feature')) {
	gsap.registerPlugin(MorphSVGPlugin);
	MorphSVGPlugin.convertToPath('circle');
	vr_feature();
}

if (document.querySelector('#book.primary-section')) {
	gsap.to('.site-project__book-tickets', {
		opacity: 0,
		duration: 0.3,
		scrollTrigger: {
			trigger: '#book',
			toggleActions: 'play none none reset',
		},
	});
}
